@import './scss/fonts';
@import './scss/vars';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: $defaultFont;
    font-size: $defaulFontSize;
    color: $defaultColor;
    line-height: 1.3;
    font-weight: 400;
    position: relative;
}

.img-responsive {
    max-width: 100%;
    display: block;
}

.container {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
}
.wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row {
    display: flex;
}

.text-field {
    position: relative;
    margin-bottom: 20px;
    &.error {
        .PhoneInput {
            border-color: red;
        }
    }
    &__label {
        font-size: 13px;
        letter-spacing: -0.08px;
        text-transform: uppercase;
        color: #878787;

    }
    &__input {
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #25AFBD;
        line-height: 47px;
        outline: none;
        font-size: 17px;
        padding-right: 26px;
    }
    &__icon {
        position: absolute;
        right: 0;
        bottom: 13px;
        cursor: pointer;
    }
    &__helper-text {
        position: absolute;
        bottom: -16px;
        left: 0;
        color: red;
        font-size: 12px;
        display: flex;
        align-items: center;
        line-height: 1;
        svg {
            margin-right: 3px;
        }
    }
    .PhoneInput {
        border-bottom: 1px solid #25AFBD;
        input {
            border: none;
            line-height: 47px;
            box-sizing: border-box;
            font-size: 17px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        .PhoneInputCountryIcon {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            overflow: hidden;
            border: none;
            box-shadow: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 26px;
                height: 26px;
            }
        }
    }
}
.btn {
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    background: $accentColor;
    border-radius: 12px;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: -0.41px;
    cursor: pointer;
    text-decoration: none;
}
.infoWindow {
    padding: 5px 0;
    text-align: center;
    &__date {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 3px;
    }
    &__time {
        font-size: 13px;
    }
}