@import '../../scss/vars';

.header {
    position: relative;
    z-index: 9;
    width: 100%;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &__logout {
        font-size: 18px;
        letter-spacing: -0.015em;
        color: $accentColor;
        border: none;
        cursor: pointer;
        background-color: transparent;
        font-weight: 700;
    }
    &__logo {
        display: flex;
        align-items: center;
    }
    &__text {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.015em;
        margin-left: 10px;
    }
}