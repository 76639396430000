.loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}
.spinner {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: conic-gradient(rgba(29, 185, 84, 25%), #25AFBD);
    position: relative;
    animation: spin 1.4s linear infinite;
    transform: translateZ(0);
    &::after {
        background: #ffffff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.lottie-view {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 91%);
    display: flex;
    align-items: center;
    justify-content: center;
    .close {
        position: absolute;
        top: 42px;
        right: 20px;
        cursor: pointer;
    }
    div {
        width: 63px;
    }
}
